<template>
  <div
    class="animate-box fadeInUp animated reply-list"
    data-animate-effect="fadeInUp"
  >
    <div
      class="col-sm-12 comment-reply-item"
      v-for="reply in list"
      :key="reply.id"
    >
      <div class="col-sm-6 name">{{ reply.name }}</div>
      <div class="col-sm-12 comment">{{ reply.text }}</div>
      <div class="date">{{ reply.date | duration }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "comment-reply-list",
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.reply-list {
  margin-top: 15px;
  padding: 0px 0 30px 0;
}

.comment-reply-item {
  padding: 5px 0 5px 30px;
}
.comment-reply-item .name {
  font-weight: bold;
}

@media (min-width: 1px) and (max-width: 767px) {
  .comment-reply-item .date {
    margin-top: -30px !important;
  }
}
</style>
