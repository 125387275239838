<template>
  <div
    class="animate-box fadeInUp animated comment-section"
    data-animate-effect="fadeInUp"
  >
    <h4>Comments ({{ comments.length }})</h4>
    <comment-form :id="id" @onAddComment="addComment" />
    <comment-list
      :id="id"
      :comments="comments"
      @onAddLike="addLike"
      @onAddCommentReply="onAddCommentReply"
    />
  </div>
</template>
<script>
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

export default {
  name: "comment-section",
  data() {
    return {};
  },
  props: {
    comments: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    addComment(comment) {
      this.$emit("onAddComment", comment);
    },
    addLike(data) {
      this.$emit("onAddLike", data);
    },
    onAddCommentReply(reply) {
      this.$emit("onAddCommentReply", reply);
      this.reply = false;
    },
  },
  components: {
    CommentList,
    CommentForm,
  },
};
</script>

<style>
.comment-section {
  padding: 30px 0px;
}
.comment-section h4 {
  font-size: 24px;
  position: relative;
  margin: 0;
  margin-bottom: 10px;
}
.comment-section h4:before {
  vertical-align: middle;
  content: "";
  display: inline-block;
  width: 50px;
  height: 1px;
  background: #444;
  position: relative;
  margin-right: 10px;
  /* bottom: 15px; */
}
</style>
