<template>
  <div class="animate-box fadeInUp animated" data-animate-effect="fadeInUp">
    <div
      class="col-sm-12 comment-item"
      v-for="comment in items"
      :key="comment.id"
    >
      <comment-list-item
        :comment="comment"
        @onAddCommentReply="onAddCommentReply"
        :id="id"
      ></comment-list-item>
    </div>

    <div class="pagination-container" v-if="pageCount > 1">
      <b-pagination
        v-model="pageNumber"
        :total-rows="totalRows"
        :per-page="pageSize"
        first-number
        last-number
        prev-text="←"
        next-text="→"
        aria-controls="my-table"
        align="center"
        limit="5"
        @change="pageChangeHandler"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import paginationMixin from "@/mixins/pagination.mixin";
import CommentListItem from "./CommentListItem.vue";
export default {
  components: { CommentListItem },
  name: "comment-list",
  data() {
    return {
      reply: false,
    };
  },
  mixins: [paginationMixin],
  props: {
    comments: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    comments: function (val) {
      this.setUp(val);
    },
  },
  methods: {
    setUp(comments) {
      this.setUpPagination(comments);
    },
    onAddCommentReply(reply) {
      this.$emit("onAddCommentReply", reply);
    },
  },
  mounted() {
    this.setUp(this.comments);
  },
};
</script>

<style>
.comment-item {
  padding: 15px 0px;
}
.comment-item .name {
  font-weight: bold;
}

.comment-item .button-reply {
  background: none !important;
  color: #327190;
  font-style: italic;
  float: right;
  padding: 0px 0px 15px 0px;
}

@media (min-width: 1px) and (max-width: 767px) {
  .comment-item .date {
    margin-top: -30px !important;
  }
}
</style>
