<template>
  <page-structure :loading="loading">
    <div class="row">
      <div
        v-if="article"
        class="col-md-12 animate-box fadeInUp animated blog-single"
        data-animate-effect="fadeInUp"
      >
        <div class="content">
          <h2 class="mt-20 text-center">{{ article.title }}</h2>
          <BlogImage
            v-if="article.images && article.images.length > 0"
            :images="article.images"
          ></BlogImage>

          <p class="date">{{ article.date }}</p>

          <p v-html="article.text"></p>
        </div>
        <div class="tools">
          <p>Share:</p>
          <div class="shared">
            <ShareNetwork
              :title="article.title"
              :description="article.shareDescription"
              :hashtags="hashtag"
            ></ShareNetwork>
          </div>
          <br />
          <comment-section
            :comments="article.comments"
            :id="$route.params.id"
            @onAddComment="addComment"
            @onAddLike="addLike"
            @onAddCommentReply="onAddCommentReply"
          />
        </div>
      </div>
    </div>
  </page-structure>
</template>

<script>
import BlogImage from "@/components/blog/BlogImage.vue";
import ShareNetwork from "@/components/blog/ShareNetwork.vue";
import CommentSection from "../components/blog/CommentSection";
export default {
  name: "blog",
  data: () => {
    return {
      loading: true,
      article: null,
    };
  },
  async mounted() {
    this.$emit("setsocial", true);
    try {
      const id = this.$route.params.id;

      const article = await this.$store.dispatch("fetchArticleById", id);
      this.article = article;
      this.loading = false;
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    async addComment(comment) {
      if (!this.article.comments) this.article.comments = [];
      this.article.comments.unshift(comment);

      await this.$store.dispatch("commentAddingNotification", {
        author: comment.name,
        comment: comment.text,
        blog: window.location.href,
      });
    },
    addLike(data) {
      this.$store
        .dispatch("addLikesCommentById", {
          blogId: this.$route.params.id,
          ...data,
        })
        .then(() => {
          this.article.comments.map((comment) => {
            if (comment.id === data.commentId) comment.likes = data.likes;
            return comment;
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async onAddCommentReply({ id, reply }) {
      this.article.comments.map((comment) => {
        if (comment.id === id) comment.reply.unshift(reply);
        return comment;
      });
      // await this.$store.dispatch("commentAddingNotification", {
      //   author: reply.name,
      //   comment: "comment reply: " + reply.text,
      //   blog: window.location.href,
      // });
    },
  },
  computed: {
    hashtag() {
      return this.article &&
        this.article.hashtags &&
        this.article.hashtags.length > 0
        ? this.article.hashtags.join(",")
        : "moldoVAN";
    },
  },
  components: { BlogImage, ShareNetwork, CommentSection }, //
  metaInfo: {
    title: "Blog article",
    titleTemplate: "%s - moldoVAN",
    htmlAttrs: {
      lang: "en",
    },
  },
};
</script>

<style lang="scss">
.blog-single .date {
  text-align: right;
  margin-top: 15px;
}

.shared {
  margin-top: -10px;
}

.shared a {
  margin-right: 10px;
  text-decoration: none;
}
.shared a span {
  font-style: italic;
}
</style>
