<template>
  <div>
    <div class="col-sm-6 name">{{ comment.name }}</div>

    <div class="col-sm-12 comment">{{ comment.text }}</div>
    <div class="date">{{ comment.date | duration }}</div>

    <button type="button" class="button-reply" @click="reply = true">
      Reply
    </button>
    <comment-reply-form
      v-if="reply"
      @onAddCommentReply="onAddCommentReply"
      :blogId="id"
      :commentId="comment.id"
    ></comment-reply-form>

    <comment-reply-list
      v-if="comment.reply && comment.reply.length > 0"
      :list="comment.reply"
    ></comment-reply-list>
  </div>
</template>

<script>
import CommentReplyForm from "./CommentReplyForm.vue";
import CommentReplyList from "./CommentReplyList.vue";
export default {
  components: { CommentReplyForm, CommentReplyList },
  name: "comment-list-item",
  data() {
    return {
      reply: false,
      disable: false,
    };
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onAddCommentReply(reply) {
      this.$emit("onAddCommentReply", { id: this.comment.id, reply });
      this.disable = false;
      this.reply = false;
    },
  },
};
</script>
