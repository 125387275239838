<template>
  <form @submit.prevent="submitHandler" class="row">
    <div class="col-sm-6">
      <div class="form-component">
        <input
          v-model="formData.name"
          type="text"
          :class="{
            invalid: $v.formData.name.$dirty && !$v.formData.name.required,
          }"
          placeholder="Name*"
        />
      </div>
    </div>
    <div class="col-sm-12">
      <div class="form-component">
        <textarea
          v-model="formData.text"
          cols="40"
          rows="3"
          placeholder="Add comment"
          :class="{
            invalid: $v.formData.text.$dirty && !$v.formData.text.required,
          }"
        ></textarea>
        <button type="submit">
          <base-icon name="arrow-next" />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "comment-form",
  data() {
    return {
      formData: {
        name: "",
        text: "",
        likes: 0,
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  validations: {
    formData: {
      name: { required },
      text: { required },
    },
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      await this.$store
        .dispatch("addComment", {
          blogId: this.id,
          formData: this.formData,
        })
        .then((res) => {
          this.$emit("onAddComment", { id: res.data, ...this.formData });

          this.formData = {
            name: "",
            text: "",
            likes: 0,
          };

          this.$v.$reset();
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style>
.form-component .invalid {
  border: solid 0.5px red;
}
</style>
