<template>
  <div>
    <div v-if="images.length == 0">0</div>
    <div v-if="images.length == 1" class="img-container">
      <img v-gallery class="img-fluid" :src="images[0].url" alt="" />
      <!-- <img v-img class="img-fluid" :src="images[0].url" alt="" /> -->
    </div>
    <div v-if="images.length > 1">
      <slick ref="slick" :options="slickOptions">
        <a href="#" v-for="image in images" :key="image.name">
          <img
            v-gallery
            class="img-fluid"
            :src="image.url"
            alt=""
            style="width: 100%; height: 200px; object-fit: cover"
          />
          <!-- <img
            v-img
            class="img-fluid"
            :src="image.url"
            alt=""
            style="width: 100%; height: 200px; object-fit: cover"
          /> -->
        </a>
      </slick>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import slickOptions from "@/config/slickOptions";
//import Viewer from 'viewerjs';
export default {
  name: "blogImage",
  data: () => {
    return {
      slickOptions: slickOptions,
    };
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  components: { Slick },
};
</script>

<style lang="scss">
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  padding: 10px;
}

.slick-arrow {
  position: sticky;
  top: 100%;
  // left: calc(50% - 100px);
}
.btn-next.slick-arrow {
  left: calc(50% + 10px);
}
.btn-back.slick-arrow {
  left: calc(50% - 40px);
}
.img-container {
  margin: auto;
  max-width: 80%;
}

@media (min-width: 1px) and (max-width: 767px) {
  .img-container {
    max-width: 100% !important;
  }
  .blog-single .date {
    margin-top: 15px !important;
  }
}
</style>
